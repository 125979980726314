import { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import Home from './components/Home'
import Generic from './components/Generic'
import Product from './components/Product'
import Group from './components/Group'
import Profile from './components/Profile'
import Resources from './components/Resources'
import Success from './components/Success'
import Testimonials from './components/Testimonials'


import {Terms, Privacy, Pricing, Refunds} from './components/Display'

function App() {
 
  // when active displays request form and disables other things
  const [popupAct, setPopupAct] = useState(false)
  const [bar, setBar] = useState(false)

  // when user clicks close button on the form
  const handleClose = () => {
    setPopupAct(false)
  }

  const handleInfo = () => {
    setPopupAct(true)
  }
  

  return (
    <Router>
      {bar && <LinearProgress/>}
      <div className="App">
      <nav class="navbar navbar-expand-lg bg-body-tertiary bar">
        <div class="container-fluid">
          {/* <span class="nav_title" id="nav_title"><Link style={{ textDecoration: 'none' }} to="/"><span class="navbar-brand h1 title">Sateek</span></Link></span> */}
          <nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="/">
    <span class="navtitle h1_nav">Sateek</span>
  </a>
</nav>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" > <Link style={{ textDecoration: 'none' }} to="/">Home</Link></a>
                <a class="nav-link active" aria-current="page" > <Link to="/product" style={{ textDecoration: 'none' }}>Products</Link></a>
              </li>
            </ul>
          </div>
        </div>
        
      </nav>

    
      <Routes>
        <Route path='/placement' element={<Generic bar={bar} setBar={setBar} type={"placement"}/>} />
        <Route path='/gate' element={<Generic bar={bar} setBar={setBar} type={"gate"}/>} />
        <Route path='/mba' element={<Generic bar={bar} setBar={setBar} type={"mba"}/>} />
        <Route path='/graphic' element={<Generic bar={bar} setBar={setBar} type={"graphic"}/>} />
        <Route path='/trading' element={<Generic bar={bar} setBar={setBar} type={"trading"}/>} />
        <Route path='/data' element={<Generic bar={bar} setBar={setBar} type={"data"}/>} />
        <Route path='/abroad' element={<Generic bar={bar} setBar={setBar} type={"abroad"}/>} />
        <Route path='/fitness' element={<Generic bar={bar} setBar={setBar} type={"fitness"}/>} />


        <Route path='/product' element={<Product/>} />
        <Route path='/resources' element={<Resources/>} />
        <Route path='/testimonials' element={<Testimonials/>} />

        <Route path='/ca' element={<Generic bar={bar} setBar={setBar} type={"ca"}/>} />
        <Route path='/success' element={<Success/>} />



        <Route path='/group' element={<Group bar={bar} setBar={setBar}/>} />
        <Route path='/' element={<Home/>} />

        <Route path='/profile/:cId' element={<Profile/>} />

        <Route path='/terms' element={<Terms/>} />
        <Route path='/privacy' element={<Privacy/>} />
        <Route path='/' element={<Home/>} />
      </Routes>
      
      <div id="footer">
        <footer class="footer">
          <div class="footer__addr">
            <h1 class="footer__logo">Sateek</h1>
                
            <h2><bold>Contact Us</bold></h2>
            <address>
              Email:&nbsp;sateekconsultation@gmail.com<br />
              Phone:&nbsp;9131455619, 9340070476<br />
            </address>
          </div>
        

          <ul class="footer__nav">
            <li class="nav__item">
              <h2 class="nav__title"><solid>Important Links</solid></h2>

              <ul class="nav__ul">
                <li>
                  <a href="#counsels">Book a Session</a>
                </li>

                <li>
                  <a href="#works">How we do it</a>
                </li>
                    
                {/* <li>
                  <a href="mailto:sateekconsultation@gmail.com">Email Us</a>
                </li> */}
                <li>
                  <Link style={{ textDecoration: 'none' }} to="/terms">Terms and Conditions</Link>
                </li>
                <li>
                  <Link style={{ textDecoration: 'none' }} to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </li>
          </ul>
          
          <div class="legal">
            <p>&copy; 2024 <span id="logocolor">Sateek</span>. All rights reserved.</p>
            
            <div class="legal" id="legal_slogan">
              <span>Made for <span id="logocolor">sateek</span> advice when needed.</span>
            </div>
          </div>
        </footer>
      </div>
      </div>
    </Router>
  );
}

export default App;