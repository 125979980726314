import './Testimonials.css'
import {useEffect } from "react"

function Testimonials() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Testimonials">
      Testimonials
    </div>
  );
}
export default Testimonials;
