import { useState, useEffect } from "react"
import { doc, getDoc, collection, addDoc, serverTimestamp, query, where, onSnapshot} from "firebase/firestore";
import emailjs from "@emailjs/browser";
import { useParams } from 'react-router-dom';
import { db } from "../firebase.config"
import { Link } from "react-router-dom";
import "./Profile.css"

function Profile() {
    const { cId } = useParams();
    const [consultant, setConsultant] = useState({})
    const [forms, setForms] = useState([])

    // form to add request for a consulatation
    const [form, setForm] = useState({
      name: "",
      email: "",
      phone: "", 
      type: "group",
      cId: "", 
      cName: "",
      reason: "",
      isActive: true,
      isComplete: false,
      resume: "",
      date: "", 
      price: 0,
      deleted: false
    })

    // when active displays request form and disables other things
    const [popupActive, setPopupActive] = useState(false)
    const [alertActive, setAlertActive] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const formsCollectionRef = collection(db, "forms")
    
    useEffect(() => {

        emailjs.init("GzUYiLPw4nklpYLTG")
        window.scrollTo(0, 0)

       
        async function fetchData() {
            const docRef =  doc(db, "consultants", cId)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setConsultant(docSnap.data())
              } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
              }
        }

        fetchData()

        const q = query(formsCollectionRef, where("isComplete", "==", false), where("cId", "==", cId));
        onSnapshot(q, snapshot => {
            setForms(snapshot.docs.map(doc => {
              
              return {
                id: doc.id,
                ...doc.data()
              }
              
            }))
          },error => {console.log(error)}
          )

       
       
      }, [cId])

    const sendEmail = async (n, m, mail) => {
      const serviceId = "service_cvkbd7h";
      const templateId = "template_axec606";
      try {
        
        await emailjs.send(serviceId, templateId, {
          name: n,
          mentor: m,
          email:mail
        });
      } catch (error) {
        console.log(error);
      } 
    };

    const formClear = () => {
      if (
        !form.name ||
        !form.email ||
        !form.phone ||
        !form.type
      ) {
        alert("Please fill out required fields")
        return false
      }
      return true
  
    }
  

  // when the user clicks submit on the form
  // checks, sends request and then empties the form for futher processing
  const handleSubmit = e => {
    e.preventDefault()

   
    if (!formClear()) return 


    console.log(form)
    addDoc(formsCollectionRef, {...form, timestamp: serverTimestamp()})
    let active = form.isActive

    if (active) {
      if (form.type == "group" || form.price === 0) {
        sendEmail(form.name, form.cName, form.email)
        window.scrollTo(0, 0)
        setAlertActive(true)
        setTimeout(() => {
          setAlertActive(false)
        }, 5000)
      } else {
        window.location.href = `https://pages.razorpay.com/pl_NnKEvhBal0mOWh/view?amount=${form.price}`
      }
      
    } else alert("The consultant is busy we will try to schedule your appointment in the next week")
   

    setForm({
      name: "",
      email: "",
      phone: "",
      type: "group", 
      cId: "", 
      cName: "",
      reason: "",
      isActive: true,
      isComplete: false,
      resume: "",
      date: "",
      price: 0,
      deleted: false
    })
    setPopupActive(false)
  }
    // when user clicks close button on the form
    const handleClose = () => {
      setForm({
        name: "",
        email: "",
        phone: "", 
        type: "group",
        cId: "", 
        cName: "", 
        reason: "",
        isActive: true,
        isComplete: false,
        resume: "",
        date: "", 
        price: 0,
        deleted: false
      })
      setPopupActive(false)
    }
  
  // displays the form and pre fills some information
  const handleView = (id, name, active, date = "", price = 0, group = true) => {
    setPopupActive(true)
    let type = group === true ? "group" : "individual"
    setIsVisible(group)
    setForm({...form, cId: id, cName: name, isActive: active, date: date, price: price, type: type})
  }


  

  return (
    <div className="Profile">


    {alertActive && <div class="alert alert-success align" role="alert">
            Appointment Booked! We will schedule your meet within a week
          </div>}
    {consultant.name &&
    <div className="upperband_profile">
    <img className="dp_profile"  src={consultant.dp}/>
    <div className="flex_col">
    <p className="field_subtitle_name_profile">{ consultant.name } <br/> <p className="field_expertise_profile">{ consultant.expertise.join(" | ") }</p></p>
    <p className="field_subtitle_company_profile">Works at<br/><span className="company_profile">{ consultant.current }</span></p>
    </div>
    <div className="setmargin">
    <div className="flex_col">
    <div className="flex_row">      
    <p className="field_subtitle_profile"> Active Requests <br/><span className="values_profile">{ forms.length}</span> </p>
    <p className="field_subtitle_profile"> Serving <br/>rate<br/> <span className="values_profile">1/week</span> </p>
    </div>
    </div>
    </div>
    </div>
    }
  

{consultant.name && <div className="consultant_profile" key={consultant.id} id="consultant_profile">
          
          
          <div className="aboutprofile"> 
          <div className="flex_col">
            <p className="field_profile">{ consultant.about }</p>
          <div className="flex_row" > 
            <p className="field_profile"><center><a href={consultant.links[0]} className="UIforcardlinks_profile" target="_blank"><span className="buttonstyleUI_profile">Resume</span></a></center></p>
            <p className="field_profile" id="about_margin"><center><a href={consultant.links[1]} className="UIforcardlinks" target="_blank"><span className="buttonstyleUI_profile">LinkedIn</span></a></center></p>
          </div>
          <div className="buttons_profile">
            <p class="custom_button_profile" id="book_button_profile" onClick={() => handleView(cId, consultant.name, consultant.isActive, "", consultant.price, consultant.group)}><span className="buttonstyleUI_profilebook"><strong>Book a Session</strong></span></p>
          </div>
          </div>
          </div>
        </div>}

        


        { popupActive && <div className="popup">
      <div class="card">
        <div className="popup-inner">
          <div class="container">
            <div class="card-heading">
              <p class="para-text">Book a Session <br/>with<br/> {form.cName}</p>
            </div>
            <img class="image1" src= 'https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/form-image.png?alt=media&token=0b8cb75f-27f7-4ccd-8d9e-48167d856fe1' alt="Your Image" />
            
          </div>
        
       

        

        {form.type === "group" && <div class="alert alert-success align" role="alert">
          Group Sessions will be of 30 minutes and will be scheduled subject to mentor availability (approx 1 week) <Link style={{ textDecoration: 'none', color: "#b94d3d" }} to="/product"> More Information</Link>
        </div>}


        {form.type === "individual" && <div class="alert alert-success align" role="alert">
          Individual sessions are paid one on one exclusive sessions<Link style={{ textDecoration: 'none', color: "#b94d3d" }} to="/product"> More Information</Link>
        </div>}

        <form onSubmit={handleSubmit}>

          <div className="form-container" class="card-form">
            <div class = "input">
              <input
                  type="text" 
                  class = "input-field"
                  value={form.name} 
                  onChange={e => setForm({...form, name: e.target.value})} />
              <label class="input-label">Name*</label>
            </div>
          </div>

          <div className="form-container" class="card-form">
            
            <div class = "input">
              <input
                  type="text" 
                  class = "input-field"
                  value={form.email} 
                  onChange={e => setForm({...form, email: e.target.value})} />
              <label class="input-label">Email*</label>
            </div>
          </div>


          <div className="form-container" class="card-form">
            
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.phone}
                onChange={e => setForm({...form, phone: e.target.value})} />
            <label class="input-label">Phone Number*</label>
            </div>
          </div>

          {isVisible &&  <div className="form-container" class="card-form">
            <div class = "input">
              <label class="input-label-type">Type of session*</label>
            </div>
              <select value={form.type} id="type" name="type" onChange={e => setForm({...form, type: e.target.value})} >
                <option value="group">Group (Free)</option>
                <option value="individual">Individual (Paid)</option>
            </select>

          </div>}


          <div className="form-container" class="card-form">
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.reason}  
                onChange={e => setForm({...form, reason: e.target.value})} />
            <label class="input-label">Reason for Session*</label>
            </div>
          </div>



          {form.type === "group" && 
          <div className="form-container" class="card-form">
            <div>
              Topics for Group Session
              <p>General queries like roadmap, dos and don'ts </p>
              <p>Avoid specific questions that take a lot of time </p>
              <p>For a more customized experience (example - Resume Review) try the individual sessions</p>
            </div>
          </div>}


          {form.type === "individual" && <div className="form-container" class="card-form">
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.resume}  
                onChange={e => setForm({...form, resume: e.target.value})} />
            <label class="input-label">Resume Link</label>
            </div>
          </div>}

          {form.type === "individual" && <div className="form-container" class="card-form" id = "cost">
            Session Cost : ₹{form.price}
          </div>}

          
        <div className="buttons">   
        
            <button type="submit" class="submit">
              Submit
            </button>
         
          <button type="button" class="remove" onClick={handleClose}>Close</button>
        
          </div>

        </form>
      </div>
    </div>
    </div>}

     </div>
  );
}
export default Profile;
