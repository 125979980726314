import './Product.css'
import {useEffect } from "react"





function Product() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Product">
      <div id="imagecontainer">
      <img class="image" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/One_on_One_Session__2_-removebg.png?alt=media&token=1d34af19-dc9c-4744-9a16-a42e486c41d5"/>
      <img class="image" src = "https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/2.png?alt=media&token=9710458d-5463-40c8-9a98-e21ff423e98c"/>
      <img class="image" src = "https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/3.png?alt=media&token=5cd633fa-89f4-48f4-a5ec-4973bd260f53"/>
</div>
      
      
    </div>
  );
}
export default Product;
